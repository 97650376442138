@font-face {
  font-family: 'CenturyGothic';
  src: url('./assets/fonts/CenturyGothic.ttf') format('TrueType');
}
@font-face {
  font-family: 'CenturyGothicBold';
  src: url('./assets/fonts/CenturyGothicBold.ttf') format('TrueType');
}
@font-face {
  font-family: 'CenturyGothicItalic';
  src: url('./assets/fonts/CenturyGothicItalic.ttf') format('TrueType');
}
@font-face {
  font-family: 'CenturyGothicBoldItalic';
  src: url('./assets/fonts/CenturyGothicBoldItalic.ttf') format('TrueType');
}
@font-face {
  font-family: 'CenturyGothicLight';
  src: url('./assets/fonts/CenturyGothicLight.ttf') format('TrueType');
}

@font-face {
  @import url('https://fonts.googleapis.com/css?family=Teko&display=swap');
  font-family: 'Teko', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;800;900&display=swap');

body {
  margin: 0;
  font-family: 'CenturyGothic', sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

html,
body {
  margin: 0 !important;
  padding: 0 !important;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiInputBase-root {
  font-family: 'CenturyGothic', sans-serif !important;
}

.MuiFormControl-marginNormal {
  width: 100%;
}

.Toastify__toast-body {
  font-family: 'CenturyGothic', sans-serif !important;
}

.Toastify__toast {
  padding: 16px !important;
}

.Toastify__toast--default {
  border-radius: 20px;
}

@media only screen and (max-width: 320px) {
  .typeform-popover-wrapper iframe {
    width: 90% !important;
  }
}
