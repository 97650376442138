@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;800;900&display=swap);
.container-timer-phone {
  width: 100%;
  text-align: center;
}

.timer-phone {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  float: none;
  margin: 0 auto;
}

.timer-phone div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #cfcfd0;
  border-radius: 50%;
  -webkit-animation: timer 1.2s linear infinite;
          animation: timer 1.2s linear infinite;
}

.timer-phone div:nth-child(2) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  top: 37px;
  left: 66px;
}

.timer-phone div:nth-child(3) {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}

.timer-phone div:nth-child(4) {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}

.timer-phone div:nth-child(5) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}

.timer-phone div:nth-child(6) {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}

.timer-phone div:nth-child(7) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}

.timer-phone div:nth-child(8) {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}

.timer-phone div:nth-child(9) {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}

.timer-phone div:nth-child(10) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}

.timer-phone div:nth-child(11) {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}

.timer-phone div:nth-child(12) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
  top: 62px;
  left: 52px;
}

.timer-phone div:nth-child(13) {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}

.timer-phone span {
  position: absolute;
  font-family: 'Teko', sans-serif;
  top: 25px;
  left: 28px;
  font-size: 25px;
  color: #000b3c;
  width: 25px;
  text-align: center;
}

@-webkit-keyframes timer {
  0%,
  20%,
  80%,
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

@keyframes timer {
  0%,
  20%,
  80%,
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

.p-phone {
  font-size: 12px;
  line-height: 18px;
  font-family: 'CenturyGothicBold';
  color: #000b3c;
}

.container-timer {
  width: 200px;
  text-align: center;
}

.timer {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  float: none;
  margin: 0 auto;
}

.timer div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #cfcfd0;
  border-radius: 50%;
  -webkit-animation: timer 1.2s linear infinite;
          animation: timer 1.2s linear infinite;
}

.timer div:nth-child(2) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  top: 37px;
  left: 66px;
}

.timer div:nth-child(3) {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}

.timer div:nth-child(4) {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}

.timer div:nth-child(5) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}

.timer div:nth-child(6) {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}

.timer div:nth-child(7) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}

.timer div:nth-child(8) {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}

.timer div:nth-child(9) {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}

.timer div:nth-child(10) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}

.timer div:nth-child(11) {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}

.timer div:nth-child(12) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
  top: 62px;
  left: 52px;
}

.timer div:nth-child(13) {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}

.timer span {
  position: absolute;
  font-family: 'Teko', sans-serif;
  top: 25px;
  left: 28px;
  font-size: 25px;
  color: white;
  width: 25px;
  text-align: center;
}

@-webkit-keyframes timer {
  0%,
  20%,
  80%,
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

@keyframes timer {
  0%,
  20%,
  80%,
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

p {
  font-size: 14px;
  line-height: 18px;
  font-family: 'CenturyGothicBold';
  color: white;
}

@font-face {
  font-family: 'CenturyGothic';
  src: url(/static/media/CenturyGothic.09f6b2ba.ttf) format('TrueType');
}
@font-face {
  font-family: 'CenturyGothicBold';
  src: url(/static/media/CenturyGothicBold.7bea0c92.ttf) format('TrueType');
}
@font-face {
  font-family: 'CenturyGothicItalic';
  src: url(/static/media/CenturyGothicItalic.87a92153.ttf) format('TrueType');
}
@font-face {
  font-family: 'CenturyGothicBoldItalic';
  src: url(/static/media/CenturyGothicBoldItalic.abd76d61.ttf) format('TrueType');
}
@font-face {
  font-family: 'CenturyGothicLight';
  src: url(/static/media/CenturyGothicLight.8917856b.ttf) format('TrueType');
}

@font-face {
  @import url('https://fonts.googleapis.com/css?family=Teko&display=swap');
  font-family: 'Teko', sans-serif;
}

body {
  margin: 0;
  font-family: 'CenturyGothic', sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

html,
body {
  margin: 0 !important;
  padding: 0 !important;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiInputBase-root {
  font-family: 'CenturyGothic', sans-serif !important;
}

.MuiFormControl-marginNormal {
  width: 100%;
}

.Toastify__toast-body {
  font-family: 'CenturyGothic', sans-serif !important;
}

.Toastify__toast {
  padding: 16px !important;
}

.Toastify__toast--default {
  border-radius: 20px;
}

@media only screen and (max-width: 320px) {
  .typeform-popover-wrapper iframe {
    width: 90% !important;
  }
}

